'use client'

import { Box, BoxProps } from '@mui/joy'
import { useRouter } from 'next/navigation'
import nProgress from 'nprogress'
import { ChangeEvent, useRef, useState } from 'react'

import { useDarkModeControl } from '@hooks/useDarkModeControl'
import useSearchHistory from '@hooks/useSearchHistory'
import useSearchKeyword from '@hooks/useSearchKeyword'
import { useHeaderStates } from '../../store/store'

import { pxToRem } from '@ui/style/muiTheme'
import IconSearchDelete from 'src/assets/icons/IconSearchDelete'
import { SearchMuiInput } from './SearchInput.styled'

import UIFlex from '@components/ui/UIFlex'
import UIText from '@components/ui/UIText'
import useMousedownOutside from '@hooks/useMousedownOutside'
import useSearchFilter from '@hooks/useSearchFilter'
import SearchButton from './SearchButton'
import SearchHistoryList from './SearchHistoryList'
import useSearchControl from '@hooks/useSearchControl'

export type TSearchHistroy = {
	keyword: string
	date: string
}

type SearchInputProps = Omit<BoxProps, 'ref'> & {
	hasDefaultKeyword?: boolean
}

const SearchInput = ({ hasDefaultKeyword = true, ...props }: SearchInputProps) => {
	const router = useRouter()
	const inputWrapperRef = useRef<HTMLDivElement>(null)
	const internalRef = useRef<HTMLDivElement>(null)

	const [placeholder, setPlaceholder] = useState('검색어를 입력해주세요')

	const { darkMode } = useDarkModeControl()
	const [, setHeaderStates] = useHeaderStates()

	const { params } = useSearchFilter()
	const { onKeywordSearch } = useSearchControl()
	const { keyword, updateKeyword, checkKeywordValidation } = useSearchKeyword({
		defaultKeyword: hasDefaultKeyword ? params.get('keyword') ?? '' : undefined,
	})
	const { searchHistory, isHistoryOpen, closeHistory, openHistory, saveHistory, deleteHistory } =
		useSearchHistory()

	useMousedownOutside(internalRef, (event) => {
		if (internalRef.current && internalRef.current.contains(event.target as Node)) {
			return
		}
		closeHistory()
	})

	const navigateToSearchPage = (kwd: string) => {
		const searchParams = new URLSearchParams(window.location.search)
		searchParams.set('keyword', kwd)
		nProgress.start()
		router.push(`/search?${searchParams.toString()}`)
	}

	const blurInput = () => {
		if (inputWrapperRef.current) {
			inputWrapperRef.current.getElementsByTagName('input')[0].blur()
		}
	}

	const handleSearch = (kwd: string, checkValidation = true) => {
		if (checkValidation && !checkKeywordValidation()) return

		// 검색어 업데이트
		updateKeyword(kwd)

		// 검색어(keyword)를 LocalStorage에 저장
		saveHistory(kwd)

		// 모달 닫기 및 검색 페이지로 이동
		closeHistory()
		setHeaderStates((prev) => ({
			...prev,
			searchOpen: false,
			megaMenuOpen: false,
		}))
		navigateToSearchPage(kwd)

		// input 포커스 해제
		blurInput()
	}

	const handleChangeKeyword = (e: ChangeEvent<HTMLInputElement>) => {
		onKeywordSearch({ value: e.target.value, setKeyword: updateKeyword })
	}

	return (
		<Box position="relative" {...props} ref={internalRef}>
			<SearchMuiInput
				ref={inputWrapperRef}
				// autoFocus={!hasDefaultKeyword}
				variant="outlined"
				placeholder={placeholder}
				endDecorator={
					<UIFlex
						flexDirection="row"
						alignItems="center"
						justifyContent="center"
						sx={{ '&> *:not(:first-child)': { marginLeft: pxToRem(12) } }}
					>
						{keyword && (
							<button type="button" onClick={() => updateKeyword('')}>
								<IconSearchDelete />
								<UIText readonly>검색어 삭제</UIText>
							</button>
						)}
						<SearchButton onClick={() => handleSearch(keyword)} darkMode={darkMode} />
					</UIFlex>
				}
				value={keyword}
				darkMode={darkMode}
				onMouseDown={() => openHistory()}
				onChange={handleChangeKeyword}
				onKeyDown={(e) => {
					if (e.key === 'Enter' && e.nativeEvent.isComposing === false) {
						handleSearch(keyword)
					}
				}}
				onFocus={() => setPlaceholder('')}
				onBlur={() => setPlaceholder('검색어를 입력해주세요')}
			/>
			{isHistoryOpen && (
				<Box position="absolute" top="calc(100% + 8px)" left={0} width="100%" zIndex={2}>
					<SearchHistoryList
						data={searchHistory}
						deleteHistory={deleteHistory}
						onClick={(historyKeyword) => {
							handleSearch(historyKeyword, false)
						}}
					/>
				</Box>
			)}
		</Box>
	)
}

export default SearchInput
