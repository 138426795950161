import useModal from '@components/layout/Modal'

const useSearchControl = () => {
	const confirm = useModal()

	/** keyword 검색 시 100자 limit validation 처리 */
	const onKeywordSearch = ({
		value,
		setKeyword,
	}: {
		value: string
		setKeyword: (value: string) => void
	}) => {
		if (value.length <= 100) {
			setKeyword(value)
			return
		}

		confirm.open({
			message: '검색어는 100자까지 입력 가능합니다.',
			buttons: [{ text: '확인' }],
		})
	}

	return {
		onKeywordSearch,
	}
}
export default useSearchControl
